define("discourse/plugins/discourse-siwe/discourse/controllers/siwe-auth-index", ["exports", "@ember/controller", "discourse/lib/plugin-api", "discourse/plugins/discourse-siwe/discourse/lib/web3modal"], function (_exports, _controller, _pluginApi, _web3modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    init() {
      this._super(...arguments);
      this.initAuth();
    },
    verifySignature(account, message, signature, avatar) {
      document.getElementById("eth_account").value = account;
      document.getElementById("eth_message").value = message;
      document.getElementById("eth_signature").value = signature;
      document.getElementById("eth_avatar").value = avatar;
      document.getElementById("siwe-sign").submit();
    },
    async initAuth() {
      const env = (0, _pluginApi.withPluginApi)("0.11.7", api => {
        const siteSettings = api.container.lookup("site-settings:main");
        return {
          PROJECT_ID: siteSettings.siwe_project_id
        };
      });
      let provider = _web3modal.default.create();
      await provider.providerInit(env);
      await provider.runSigningProcess(async res => {
        try {
          const [account, message, signature, avatar] = res;
          this.verifySignature(account, message, signature, avatar);
        } catch (e) {
          console.error(e);
        }
      });
    },
    actions: {
      async initAuth() {
        this.initAuth();
      }
    }
  });
});